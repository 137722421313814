<template>
  <div class="mt-1 mx-3">
    <register-company></register-company>
  </div>
</template>

<script>
import RegisterCompany from "@/components/profile/companies-products/insurers/create.vue";
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    RegisterCompany,
  },
  created() {
    this.notifyQueue({
      text: "Compagnie",
      path: "module.PROF",
      tab: "#Companies",
      level: 3,
    });
    this.notifyQueue({
      text: "Inserisci Compagnia",
      path: "insurers.create",
      level: 4,
    });
  },
};
</script>
