<template>
  <div class="mt-1 mx-3">
    <companies>
    </companies>
  </div>
</template>

<script>
import Companies from '@/components/profile/companies-products/insurers/index.vue'
export default {
  props: {
    msg: String
  },
  components: {
    Companies,
  },
  created() {
  }
}
</script>
