<template>
  <div class="mt-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="ok">
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <b-card header="Dati Identificativi" header-tag="header">
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].display.label"
                        vid="display"
                        :label="beForm[rep].display.label"
                        v-model="form[rep].display"
                        placeholder="Inserisci un codice"
                        :rules="getRules('display')"
                        maxlength="3"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        :name="beForm[rep].importer_id.label"
                        vid="importer_id"
                        :label="beForm[rep].importer_id.label"
                        v-model="form[rep].importer_id"
                        @input="onInputImporterId"
                        :options="beForm[rep].importer_id.options"
                        :rules="getRules('importer_id')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].title.label"
                        vid="title"
                        label="Descrizione Compagnia"
                        v-model="form[rep].title"
                        placeholder="Inserisci una descrizione"
                        :rules="getRules('title')"
                      />
                    </div>
                  </b-row>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-select
                        name="
                          exclude_calc_extra_vehicle_branch.label
                        "
                        vid="exclude_calc_extra_vehicle_branch"
                        label="
                          Escludi ricalcolo ramo danni extra auto
                        "
                        v-model="form.exclude_calc_extra_vehicle_branch"
                        :options="options"
                        :disabled="!form[rep].importer_id"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        name="exclude_calc_life_branch"
                        vid="exclude_calc_life_branch"
                        label="Escludi ricalcolo ramo vita"
                        v-model="form.exclude_calc_life_branch"
                        :options="options"
                        :disabled="!form[rep].importer_id"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        name="exclude_calc_vehicle_branch"
                        vid="exclude_calc_vehicle_branch"
                        label="Escludi ricalcolo ramo auto"
                        v-model="form.exclude_calc_vehicle_branch"
                        :options="options"
                        :disabled="!form[rep].importer_id"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <b-card header="Mandato" header-tag="header" class="mt-2">
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[mand_code].code.label"
                        vid="code"
                        label="Codice Mandato"
                        v-model="form[mand_code].code"
                        :rules="getRules('code', mand_code)"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[mand_code].title.label"
                        vid="title"
                        label="Descrizione Mandato"
                        v-model="form[mand_code].title"
                        :rules="getRules('title', mand_code)"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <div class="mt-2" v-show="customInputs[rep].length">
                <b-card header="Attributi personalizzati" header-tag="header">
                  <b-card-text>
                    <custom-inputs
                      :customInputs="customInputs[rep]"
                      v-model="form[rep]"
                      :beForm="beForm[rep]"
                      :beRules="beRules[rep]"
                    />
                  </b-card-text>
                </b-card>
              </div>
              <div class="py-2 mb-4">
                <b-button
                  @click="handleSubmit(onSubmit)"
                  type="button"
                  :disabled="invalid"
                  variant="lisaweb"
                  size="sm"
                >
                  Salva
                </b-button>
                <b-button @click="resetForm()" variant="lisaweb" size="sm"
                  >Reset</b-button
                >
                <b-button
                  @click="$router.back()"
                  variant="outline-lisaweb"
                  size="sm"
                  class="btn-reset float-right"
                  ><b-icon-chevron-double-left
                    font-scale="0.9"
                  ></b-icon-chevron-double-left
                  >Torna indietro</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          {{ errorMessage }}
        </div>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import CustomInputs from "@/components/form/CustomInputs";
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
import ShortcutMixin from "@/mixins/ShortcutMixin";
import FormMixin from "@/mixins/FormMixin";
import { mapGetters } from "vuex";

export default {
  mixins: [FormMixin, ShortcutMixin],
  data() {
    return {
      repository: "insurer",
      id: this.$route.params.id,
      options: [
        { text: "Si", value: "Y" },
        { text: "No", value: "N" },
      ],
      form: {
        exclude_calc_extra_vehicle_branch: null,
        exclude_calc_life_branch: null,
        exclude_calc_vehicle_branch: null,
        insurer: {
          display: null,
          importer_id: null,
          title: null,
        },
        mandate_code: {
          code: null,
          title: null,
          insurer_id: null,
          is_expired: "N",
        },
      },
    };
  },
  components: {
    BaseIcon,
    CustomInputs,
    BaseInput,
    BaseSelect,
  },
  methods: {
    onSubmit() {
      this.isLoading = true;
      this.store(this.repository)
        .then((response) => {
          this.addtoInsurer(
            response.data.data.id,
            "risk_type",
            this.setPayload()
          )
            .then((response) => {
              this.storeMandateCode(response.data.data.id);
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: `${errMsg}`,
              });
              this.isLoading = false;
            });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.isLoading = false;
        });
    },
    storeMandateCode(insurer_id) {
      this.form[this.mand_code].insurer_id = insurer_id;
      this.store(this.mand_code)
        .then(() => {
          this.$store.dispatch("auth/mandate_codes");
          this.$store.dispatch("auth/insurers");
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: Compagnia Creata`,
          });
          this.shortcut("module.PROF", null, "#Companies", "filterInsurer");
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    defaultBranchConfig() {
      this.form.exclude_calc_extra_vehicle_branch = "N";
      this.form.exclude_calc_life_branch = "N";
      this.form.exclude_calc_vehicle_branch = "N";
    },
    onInputImporterId(val) {
      if (!val) {
        this.defaultBranchConfig();
      }
    },
    setPayload() {
      return {
        risk_type: {
          [this.getRisks().find((risk) => risk.code === "P").value]: {
            is_excluded: this.form.exclude_calc_vehicle_branch,
          },
          [this.getRisks().find((risk) => risk.code === "L").value]: {
            is_excluded: this.form.exclude_calc_life_branch,
          },
          [this.getRisks().find((risk) => risk.code === "D").value]: {
            is_excluded: this.form.exclude_calc_extra_vehicle_branch,
          },
        },
      };
    },
    addtoInsurer(insurer_id, relation, payload) {
      const Repo = RepositoryFactory.get("insurer");
      return Repo.pivot_store(insurer_id, relation, payload);
    },
    ...mapGetters("auth", {
      getRisks: "risks",
    }),
  },
  created() {
    this.isLoading = true;

    const ins = this.fetchCreateForm(this.repository);
    const mc = this.fetchCreateForm("mandate_code");

    // other form:
    Promise.all([ins, mc])
      .then(() => {
        this.defaultBranchConfig();

        this.isLoading = false;
      })
      .catch((error) => {
        let errMsg = this.$getErrorMessage(error);
        this.$showSnackbar({
          preset: "error",
          text: `${errMsg}`,
        });
        this.isLoading = false;
      });
  },
  computed: {
    rep() {
      return this.repository;
    },
    mand_code() {
      return "mandate_code";
    },
  },
};
</script>
